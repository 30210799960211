// const pre = window.host + '/' + window.app + '/'
const pre = ''
const set = (name, value,time=0) => {
    let param = JSON.stringify({
        time:time==0?0:new Date().getTime()+time*60000,
        token:value
    })
    if (window.storageType == 'local') {
        localStorage.setItem(pre + name, param)
    } else {
        sessionStorage.setItem(name, param)
    }
    // value = typeof(value) == 'object'?JSON.stringify(value):value;
    // if (window.storageType == 'local') {
    //     localStorage.setItem(pre + name, value)
    // } else {
    //     sessionStorage.setItem(name, value)
    // }
}
const get = (name) => {
    let res;
    if (window.storageType == 'local') {
        res = localStorage.getItem(pre + name)
    } else {
        res = sessionStorage.getItem(name)
    }
    if(!res) return false;
    res = JSON.parse(res);
    if(res.time == 0 || res.time>new Date().getTime()){
        return res.token;
    }
    return false;
    // let isJson;
    // try{
    //     JSON.parse(res)
    //     isJson=true;
    // }catch(error){
    //     isJson=false;
    // }
    // return isJson?JSON.parse(res):res
}
const remove = (name) => {
    if (window.storageType == 'local') {
        localStorage.removeItem(pre + name)
    } else {
        sessionStorage.removeItem(name)
    }
}
const clear = (name)=> {
    if (window.storageType == 'local') {
        localStorage.clear()
    } else {
        sessionStorage.clear()
    }
}
export default {
    set,
    get,
    remove,
    clear
};
