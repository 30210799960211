import {createRouter, createWebHistory} from 'vue-router'

const Layout = () => import('../views/layout/index.vue')
const routes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/index/index')
            },
        ]
    },
    {
        path: '/record',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '工作记录',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/record/list')
            },
            {
                path: 'statistics',
                meta: {
                    title: '工作量统计',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/record/statistics')
            },
            {
                path: 'special',
                meta: {
                    title: '特殊记录',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/record/special')
            },
            {
                path: 'maintenance',
                meta: {
                    title: '维护记录',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/record/maintenance')
            },
        ]
    },
    {
        path: '/task',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '工单列表',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/task/list')
            },
            {
                path: 'add',
                meta: {
                    title: '添加工单',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/task/add')
            },
            {
                path: 'add/:id',
                meta: {
                    title: '修改工单',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/task/add')
            },
            {
                path: 'sign',
                meta: {
                    title: '程序谈单',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/task/sign')
            },
        ]
    },
    {
        path: '/index',
        meta: {
            title: '测试'
        },
        component: () => import(/* webpackChunkName:'second' */ '../views/index/index')
    },
    {
        path: '/operator',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '操作员列表',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/operator/list.vue')
            }, {
                path: 'group',
                meta: {
                    title: '操作员组',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/operator/group.vue')
            }
        ]
    },
    {
        path: '/setting',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '参数设置',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/list')
            },
            {
                path: 'banner',
                meta: {
                    title: 'banner设置',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/banner')
            },
            {
                path: 'article/:type',
                meta: {
                    title: '用户协议/隐私政策',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/article')
            },
            {
                path: 'managefield',
                meta: {
                    title: '参数管理',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/managefield')
            },
            {
                path: 'params',
                meta: {
                    title: '平台参数',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/params')
            },
            {
                path: 'wechat',
                meta: {
                    title: '微信相关',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/wechat')
            },
        ]
    },
    {
        path: '/login',
        meta: {
            title: '登录'
        },
        component: () => import(/* webpackChunkName:'second' */ '../views/index/login')
    }, {
        path: '/menu',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '菜单列表',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/menu/list.vue')
            }
        ]
    },
    /* 本段代码放在最后，未设置路由的地址跳转404 */
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
    {
        path: '/404',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '页面不存在'
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/404')
            },
        ]
    },
    /** 本段代码放在最后，未设置路由的地址跳转404 */
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
