import { ElMessage } from 'element-plus'
import { ElLoading } from 'element-plus'
import { ref } from 'vue'
import axios from "axios";
import storage from './storage'
import router from '../router'

const $axios = axios.create({
    baseURL: window.host + '/' + window.app,
    method: 'post',
    timeout: 10000, // 请求超时时间
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    showInfo: 1,
    showLoading: 1
});
$axios.interceptors.request.use(
    config => {
        if (storage.get('token')) {
            config.headers['token'] = storage.get('token')
        }        
        config.data = config.data ? config.data : {}
        let url = config.url;
        if (config.method == 'get') { 
            if (config.data) {
                url = url + '?';
                for (const propName of Object.keys(config.data)) {
                    const value = config.data[propName];
                    var part = encodeURIComponent(propName) + "=";
                    if (value !== null && typeof (value) !== "undefined") {
                        if (typeof value === 'object') {
                            for (const key of Object.keys(value)) {
                                if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                                    let data = propName + '[' + key + ']';
                                    let subPart = encodeURIComponent(data) + '=';
                                    url += subPart + encodeURIComponent(value[key]) + '&';
                                }
                            }
                        } else {
                            url += part + encodeURIComponent(value) + "&";
                        }
                    }
                }
                url = url.slice(0, -1);
            }
        } 
        config.url = url;
        if (config.showLoading == 1) {
            addLoading();
        }
        // 加载loading

        return config;
    },
    error => {
        //  请求发生错误，抛出异常
        Promise.reject(error);
    }
);

// 响应拦截器
$axios.interceptors.response.use(
    response => {
        // 取消加载 loading
        if (response.config.showLoading == 1) cancelLoading();
        if (response.data.token) {
            storage.set('token', response.data.token.token,response.data.token.time)
        }
        switch (response.data.status) {
            case 1:
                if (response.config.showInfo == 1) {
                    ElMessage.success({ message: response.data.info })
                }
                return response.data;
            case 0:
                ElMessage.error({ message: response.data.info })
                return response.data;
            case 9:
                ElMessage.error({ message: response.data.info })
                localStorage.removeItem(window.host + '/' + window.app + 'token');
                router.push('/login')
                break
            default:
                return response.data
        }
    },
    error => {
        // 取消加载 loading
        cancelLoading();
        if (error && error.response) {
            const status = error.response.status
            switch (status) {
                case 400:
                    ElMessage.error("请求错误");
                    break;
                case 401:
                    ElMessage.error("未授权，请重新登录");
                    break;
                case 403:
                    ElMessage.error("登录过期，请重新登录");
                    break;
                case 404:
                    ElMessage.error("请求错误，未找到相应的资源");
                    break;
                case 408:
                    ElMessage.error("请求超时");
                    break;
                case 500:
                    ElMessage.error("服务器错误");
                    break;
                case 504:
                    ElMessage.error("网络超时");
                    break;
                default:
                    ElMessage.error("请求失败");
                // ElMessage.error({ message: error.response.data.message })
            }
        } else {
            ElMessage.error("服务器超时");
            // if (JSON.stringify(error).includes("timeout")) {
            //     error.code = "TIMEOUT";
                // error.message = "服务器响应超时，请刷新页面";
            // }
        }
        return Promise.reject(error);
    },
);

axios.defaults.withCredentials = true

let loadCount = 0;
let loadingInstance = ref(null);
// 加载loading
const addLoading = () => {
  loadCount++;
  if (loadCount === 1) {
    loadingInstance.value = ElLoading.service({
      fullscreen: false,
      text: "正在请求数据中....",
      background: "rgba(0, 0, 0, 0)",
    });
  }
};
// 取消加载loading
const cancelLoading = () => {
  loadCount--;
  if (loadCount === 0) {
    loadingInstance.value.close();
  }
};
// -------------------------配置全局loading-----------------------
// 5.导出 axios 实例

export default $axios